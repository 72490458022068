import React, { useCallback } from 'react';
import { disconnect, Token, tokenProvider } from '@mindbaz/app-manager';
import { IconButton } from '@mindbaz/ui-v2';
import { useQuery } from '@tanstack/react-query';

import { isAdminMotherCompagnies } from 'packages/endpoints/header';
import { logout } from 'packages/endpoints/common';
import dashboardsLogo from '../../assets/mbz-dash-logo.png';
import logo from '../../assets/mbz-logo.png';
import {
  StyledHeader,
  StyledImg,
  StyledWrapperImg,
  StyledWrapperNav,
} from './styledComponents';

const Header = () => {
  const handleDisconnect = useCallback(() => {
    tokenProvider().removeAll();
    tokenProvider().remove(Token.REMEMBER_ME, '.mindbaz.com');
    disconnect();
    logout();
  }, []);

  const isDashboardsUrl = window.location.pathname === '/dashboards';
  const isSnapshotUrl = window.location.pathname === '/snapshot';

  // react query queries
  const { data: isAdmin } = useQuery(['isAdmin'], () =>
    isAdminMotherCompagnies()
  );

  const handleSettingsIconClick = useCallback(() => {
    const adminUrl = process.env.REACT_APP_ADMIN_URL;
    if (isSnapshotUrl) {
      window.location.replace(`${adminUrl}snapshot`);
    } else if (isDashboardsUrl) {
      window.location.replace(`${adminUrl}dashboards`);
    } else {
      window.location.replace(`${adminUrl}`);
    }
  }, [isDashboardsUrl, isSnapshotUrl]);

  return (
    <StyledHeader>
      <StyledWrapperImg>
        {isDashboardsUrl ? (
          <StyledImg
            src={dashboardsLogo}
            alt="Mindbaz-dashboard logo"
            loading="lazy"
          />
        ) : (
          <StyledImg src={logo} alt="Mindbaz logo" loading="lazy" />
        )}
      </StyledWrapperImg>
      <StyledWrapperNav>
        {isAdmin && (
          <IconButton size={36} onClick={handleSettingsIconClick}>
            settings
          </IconButton>
        )}
        <IconButton size={36} onClick={handleDisconnect}>
          power_settings_new
        </IconButton>
      </StyledWrapperNav>
    </StyledHeader>
  );
};

export default Header;
