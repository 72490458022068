import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@mindbaz/app-manager';
import { Autocomplete, Switch, Tabs } from '@mindbaz/ui-v2';
import { useQuery } from '@tanstack/react-query';

import { getDailyReportAccess } from 'packages/endpoints/access';
import { getPerson } from 'packages/endpoints/common';
import { getDatabases } from 'packages/endpoints/dailyReport';
import TitleDescriptionBlock from 'components/common/TitleDescriptionBlock';
import TabInformations from './TabInformations';
import { Flex, AutocompleteSize, LinkCustom } from './styledComponents';
import useCards from './useCards';
import { TDatabase, TDateAutocomplete } from './types';

const DailyReport = () => {
  const { translate } = useTranslation();

  const { data: dailyReportAccess } = useQuery(['dailyreport-access'], () =>
    getDailyReportAccess()
  );

  if (!dailyReportAccess.userHasAccess) {
    window.location.replace(`${process.env.REACT_APP_RUBIXO_URL}accessdenied`);
  }

  const queryParameters = new URLSearchParams(window.location.search);
  const dateParameter = queryParameters.get('date');
  const newDateparam = useMemo(() => {
    return dateParameter && new Date(dateParameter);
  }, [dateParameter]);

  const monthDate = (date: Date) => {
    return date.toLocaleString('en', {
      month: 'short',
    });
  };

  const dayDate = (date: Date) => {
    return date.toLocaleString('en', {
      weekday: 'long',
    });
  };

  const formatLabelDate = useCallback((date: Date) => {
    return `${dayDate(date)}, ${monthDate(date)} ${date && date.getDate()} ${
      date && date.getFullYear()
    }`;
  }, []);

  const sevenPreviousDays: TDateAutocomplete[] = [...Array(7)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - i - 1);
    d.setHours(d.getHours() + i);
    return {
      label: formatLabelDate(d),
      date: d.toISOString(),
    };
  });

  const defaultDateSelected = newDateparam
    ? {
        label: formatLabelDate(newDateparam),
        date: dateParameter,
      }
    : sevenPreviousDays[0];

  const { data: person } = useQuery(['get-person'], () => getPerson());
  const { data: databases } = useQuery(
    ['getDatabases'],
    () => person && getDatabases(person.idCompany)
  );
  const databasesOptions = useMemo(() => {
    return databases
      .sort((a: TDatabase, b: TDatabase) =>
        a.siteName.localeCompare(b.siteName)
      )
      .map((database: TDatabase) => {
        return {
          label: database.siteName,
          ...database,
        };
      });
  }, [databases]);

  const [selectedDate, setselectedDate] =
    useState<TDateAutocomplete>(defaultDateSelected);
  const [appleMPPChecked, setAppleMPPChecked] = useState(true);
  const [selectedDatabase, setSelectedDatabase] = useState(databasesOptions[0]);

  const handleAutocompleteChangeTabInformations = useCallback(
    (_event: any, newValue: any) => {
      setSelectedDatabase(newValue);
    },
    []
  );

  const { generalCards, performancesCards } = useCards(
    selectedDate,
    selectedDatabase,
    appleMPPChecked
  );

  const items = useMemo(
    () => [
      {
        id: 0,
        content: <TabInformations cards={generalCards} />,
        label: translate('dailyReport.tabs.general.title'),
      },
      {
        id: 1,
        content: (
          <TabInformations
            typeTab="performances"
            cards={performancesCards}
            options={databasesOptions}
            value={selectedDatabase}
            onChange={handleAutocompleteChangeTabInformations}
          />
        ),
        label: translate('dailyReport.tabs.performance.title'),
      },
    ],
    [
      translate,
      generalCards,
      performancesCards,
      databasesOptions,
      selectedDatabase,
      handleAutocompleteChangeTabInformations,
    ]
  );

  const handleAutocompleteChange = useCallback(
    (_event: any, newValue: TDateAutocomplete) => {
      setselectedDate(newValue);
    },
    []
  );

  const handleDisplayappleMPP = useCallback(
    (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
      setAppleMPPChecked(isChecked);
    },
    [setAppleMPPChecked]
  );

  const dateTitle = selectedDate.label;

  return (
    <>
      <Flex className="justify-between">
        <TitleDescriptionBlock
          title="dailyReport.title"
          description="dailyReport.description"
          values={dateTitle}
        />
        <AutocompleteSize>
          <Autocomplete
            label={translate('dailyReport.displayStatistics')}
            getOptionLabel={(option: any) => option.label}
            onChange={handleAutocompleteChange}
            options={sevenPreviousDays}
            value={selectedDate}
          />
        </AutocompleteSize>
      </Flex>
      <Flex className="justify-end">
        <Switch
          checked={appleMPPChecked}
          onChange={handleDisplayappleMPP}
          label={
            <>
              {translate('dailyReport.switch_apple_mpp.label')}
              <LinkCustom
                target="_blank"
                rel="noopener noreferrer"
                href={translate('dailyReport.switch_apple_mpp.link')}
              >
                {translate('dailyReport.switch_apple_mpp.linkText')}
              </LinkCustom>
              {translate('dailyReport.switch_apple_mpp.endLabel')}
            </>
          }
        />
      </Flex>
      <Tabs items={items} backgroundColor="#f5f5f5" />
    </>
  );
};

export default DailyReport;
